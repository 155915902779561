<template>
  <v-container class="mb-8" fluid>
    <layout :back="true" />
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pb-0 text-h5 text-center font-weight-medium"
        >{{ $t("faqs.faqs") }}</v-col
      >
    </v-row>
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col cols="12" class="text-center text-subtitle-2">{{
        $t("faqs.description")
      }}</v-col></v-row
    >
    <v-row class="ma-0 pa-0 mb-10" justify-sm="center">
      <v-col cols="12" sm="8" md="5" class="ma-0 pa-0">
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(faq, index) in faqs"
            :key="index"
            color="transparent"
            class="ma-0 pa-0 my-1"
          >
            <v-expansion-panel-header class="primary--text text-h6">
              <span :id="'text' + faq.id"></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-2 ml-2" eager :id="'answer' + faq.id">
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-footer :absolute="true" color="transparent" class="primary--text">
      <v-row class="ma-0 mb-2">
        <v-col class="text-center">
          <span class="text-subtitle-1"
            >{{ $t("contact.any_questions") }}
            <a @click="redirectContactUs" class="font-weight-bold">{{
              $t("contact.us")
            }}</a></span
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
// Configuration
import config from "@/config";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Layout
  },
  computed: {
    ...mapGetters({ faqs: "legal/faqs" }),
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ isLogged: "user/isLogged" }),
    candidateUserType() {
      return (
        config.CONSTANTS.CANDIDATE_USER_TYPE == this.userType.toUpperCase()
      );
    },
    pharmacyUserType() {
      return config.CONSTANTS.PHARMACY_USER_TYPE == this.userType.toUpperCase();
    }
  },
  async mounted() {
    this.enableLoading();
    if (this.isLogged) {
      await this.getGlobalFaqs();
      if (this.candidateUserType) {
        await this.getCandidateFaqs();
      } else if (this.pharmacyUserType) {
        await this.getPharmacyFaqs();
      }
    } else {
      await this.getAllFaqs();
    }
    this.renderFaqs();
    this.disableLoading();
  },
  methods: {
    ...mapActions({ getFaqs: "legal/getFaqs" }),
    ...mapActions({ cleanFaqs: "legal/cleanFaqs" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    redirectContactUs() {
      this.$router.push("contact");
    },
    async getAllFaqs() {
      await this.getFaqs({ topic: config.CONSTANTS.GLOBAL_TOPIC });
      await this.getFaqs({ topic: config.CONSTANTS.CANDIDATE_USER_TYPE });
      await this.getFaqs({ topic: config.CONSTANTS.PHARMACY_USER_TYPE });
    },
    async getPharmacyFaqs() {
      await this.getFaqs({ topic: config.CONSTANTS.PHARMACY_USER_TYPE });
    },
    async getCandidateFaqs() {
      await this.getFaqs({ topic: config.CONSTANTS.CANDIDATE_USER_TYPE });
    },
    async getGlobalFaqs() {
      await this.getFaqs({ topic: config.CONSTANTS.GLOBAL_TOPIC });
    },
    renderFaqs() {
      this.faqs.map(f => {
        document.getElementById("text" + f.id).innerHTML += f.text;
        document.getElementById("answer" + f.id).innerText += f.answer;
      });
    }
  },
  watch: {
    mounted() {}
  },
  beforeDestroy() {
    this.cleanFaqs();
  }
};
</script>
<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
}
</style>
